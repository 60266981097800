<template>
  <div>
    <template v-if="!empty(value)">
      <img
        :width="width > 0 ? width : null"
        :height="height > 0 ? height : null"
        @click="handlePictureCardPreview(value)"
        class="el-upload-list__item-thumbnail"
        style="cursor: hand"
        :src="imUrl + value"
        alt=""
      />
    </template>
    <template>
      <img
        width="50px"
        class="el-upload-list__item-thumbnail"
        :src="no_img"
        v-if="empty(value)"
        alt=""
      />
    </template>
    <v-dialog
      v-model="dialogVisible"
      width="600px"
      transition="dialog-bottom-transition"
    >
      <img width="100%" :src="imUrl + dialogImageUrl" alt="" />
    </v-dialog>
  </div>
</template>
<script>
export default {
  watch: {
    value: function () {
      this.selectedValue = this.value;
    },
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    folder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    mini: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "150px",
    },
    height: {
      type: String,
      default: "0px",
    },
  },
  newd() {},
  components: {},
  data() {
    return {
      selectedvalue: "",
    };
  },
  methods: {
    removeFile() {
      this.selectedvalue = null;
      this.$emit("input", null);
    },
  },
  name: "ImageField",
};
</script>
